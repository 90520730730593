import { useRef } from 'react';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import {
  Box,
  Stack,
  Button,
  FileInput,
  StackIconRow,
  StackIconWrapper,
  DateFieldWrapper,
} from '~/components/routes/ClinicAdd/ClinicAdd.styled';
import { IconButton } from '@mui/material';
import { Link } from '~/components/routes/ClinicDetail/ClinicDetail.styled';
import { DateField } from '~/components/fields/DateField';
import Icon from '~/components/commons/Icon';
import { Tooltip } from '~/components/commons/Tooltip';

const FILE_CONSTRAINTS = {
  MAX_SIZE: 10 * 1024 * 1024,
  ALLOWED_TYPES: ['jpg', 'png', 'pdf'],
};

export const ContractsFile = ({
  value,
  item,
  index,
  onChangeValue,
}: {
  value: any;
  item: any;
  index: number;
  onChangeValue: any;
}) => {
  const snackbar: any = useSnackbarContext();
  const fileRef: any = useRef();

  const handleChangeImage = (e: any, index?: number) => {
    let target = e.target;
    if (target.files[0] === undefined) {
      return;
    }

    const { size, name } = target.files[0];
    const fileType = name.split('.').pop()?.toLowerCase();

    if (size > FILE_CONSTRAINTS.MAX_SIZE) {
      snackbar.alert('10MB 이하로만 첨부가능합니다.');
      return;
    }

    if (!FILE_CONSTRAINTS.ALLOWED_TYPES.includes(fileType)) {
      snackbar.alert('jpg 또는 PNG 또는 PDF 파일만 첨부가능합니다.');
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event: any) {
      let newValue: any = {};
      const file = target.files[0];

      if (file.type === 'application/pdf') {
        newValue.contractsFiles = (value.contractsFiles || []).map(
          (v: any, i: number) => {
            if (i === index) {
              return {
                ...v,
                contractId: v.contractId ? v.contractId : null,
                contractFile: file,
                contractFileName: file.name,
              };
            } else {
              return v;
            }
          }
        );
        onChangeValue({ ...newValue });

        return;
      }

      const img: any = new Image();
      img.src = event.target.result;

      img.onload = function () {
        let newValue: any = {};
        const file = target.files[0];
        newValue.contractsFiles = (value.contractsFiles || []).map(
          (v: any, i: number) => {
            if (i === index) {
              return {
                ...v,
                contractId: v.contractId ? v.contractId : null,
                contractFile: file,
                contractFileName: file.name,
              };
            } else {
              return v;
            }
          }
        );
        onChangeValue({ ...newValue });
      };
    };
    reader.readAsDataURL(target.files[0]);
  };

  return (
    <StackIconRow key={index}>
      <Stack className="contract-list-wrapper" flexDirection={'row'}>
        <Box className="label">
          계약서
          <Button
            variant="outlined"
            className="file-upload-btn"
            onClick={() => fileRef.current.click()}
          >
            첨부하기
          </Button>
          <FileInput
            ref={fileRef}
            onChange={(e) => {
              handleChangeImage(e, index);
            }}
          />
        </Box>
        <Box className="value">
          <div className="contracts-wrapper" key={index}>
            <div>
              {item.contractId && item.url && !item.contractFile ? (
                <Link href={item.url} target="_blank">
                  <Tooltip title={item.url}>
                    <div>{item.url}</div>
                  </Tooltip>
                </Link>
              ) : item.contractFile && item.contractFileName ? (
                <Tooltip title={item.contractFileName}>
                  <div>{item.contractFileName}</div>
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          </div>
        </Box>
        <Box className="label">계약서 작성일자</Box>
        <Box className="value">
          <DateFieldWrapper>
            <DateField
              dateFormat={'yyyy/MM/dd'}
              placeholder="작성일자를 선택하세요"
              value={
                item.contractSignDate ? new Date(item.contractSignDate) : null
              }
              onChange={(date) => {
                let newValue: any = {};
                newValue.contractsFiles = (value.contractsFiles || []).map(
                  (v: any, i: number) => {
                    if (i === index) {
                      return {
                        ...v,
                        contractSignDate: date,
                      };
                    } else {
                      return v;
                    }
                  }
                );
                onChangeValue({ ...newValue });
              }}
            />
          </DateFieldWrapper>
        </Box>
      </Stack>
      <StackIconWrapper>
        {value?.contractsFiles.length - 1 === index ? (
          <IconButton
            onClick={(e) => {
              if (value.contractsFiles.length < 10) {
                let contracts = [
                  ...value.contractsFiles,
                  {
                    contractId: null,
                    contractFile: null,
                    contractFileName: null,
                    contractSignDate: null,
                  },
                ];
                onChangeValue({ contractsFiles: contracts });
              } else {
                snackbar.alert('최대 10건 까지 가능합니다.');
              }
            }}
          >
            <Icon variant="plus" />
          </IconButton>
        ) : (
          <IconButton
            onClick={(e) => {
              if (value.contractsFiles.length > 1) {
                onChangeValue({
                  contractsFiles: value.contractsFiles.filter(
                    (f: any, i: number) => i !== index
                  ),
                });
              }
            }}
          >
            <Icon variant="minus" />
          </IconButton>
        )}
      </StackIconWrapper>
    </StackIconRow>
  );
};
