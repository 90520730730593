import { Component, ReactNode } from 'react';
import { Button } from '@mui/material';
import Icon from '~/components/commons/Icon';
import * as Sentry from '@sentry/browser';
import { ApiException, ApiExceptionName } from '~/api/apiClient';
import { Box, Description } from './ErrorBounday.styled';
import { useSnackbarContext } from './SnackbarProvider';

interface ErrorBoundaryProps {
  children: ReactNode;
  source?: string;
  snackbar: any;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorType: string;
  errorMessage: string;
}

class ErrorBoundaryClass extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      errorType: 'DEFAULT',
      errorMessage: '알 수 없는 오류가 발생했습니다.',
    };

    window.addEventListener('error', this.handleGlobalError);
    window.addEventListener(
      'unhandledrejection',
      this.handleUnhandledRejection
    );
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.handleGlobalError);
    window.removeEventListener(
      'unhandledrejection',
      this.handleUnhandledRejection
    );
  }

  private handleGlobalError = (event: ErrorEvent) => {
    const error = event.error;
    if (error instanceof ApiException && error.code === 401) {
      this.handle401Error();
      return;
    }

    event.preventDefault();
    this.setState({
      hasError: true,
      errorType: 'RUNTIME',
      errorMessage: error?.message || '알 수 없는 오류가 발생했습니다.',
    });
  };

  private handleUnhandledRejection = (event: PromiseRejectionEvent) => {
    const error = event.reason;
    if (error instanceof ApiException && error.code === 401) {
      this.handle401Error();
      return;
    }

    event.preventDefault();
    this.setState({
      hasError: true,
      errorType: 'ASYNC',
      errorMessage: error?.message || '비동기 작업 중 오류가 발생했습니다.',
    });
  };

  private handle401Error = () => {
    const localAuth = localStorage.getItem('access_token');
    if (localAuth) {
      this.props.snackbar.alert('인증이 만료되었습니다. 다시 로그인하세요.');
    }
    window.location.href = '/login';
  };

  private shouldHandleError(error: Error): boolean {
    if (error instanceof ApiException) {
      if (error.code === 401) {
        this.handle401Error();
        return false;
      }

      if (error.code === 403) {
        return true;
      }

      if (
        error.code === null &&
        error.name === ApiExceptionName.FAILED_TO_FETCH
      ) {
        return true;
      }
    }

    return true;
  }

  static getDerivedStateFromError(error: Error) {
    if (error instanceof ApiException) {
      if (error.code === 401) {
        return null;
      }

      if (error.code === 403) {
        return {
          hasError: true,
          errorType: 'FORBIDDEN',
          errorMessage: '접근 권한이 없습니다.',
        };
      }
    }

    return {
      hasError: true,
      errorType: 'DEFAULT',
      errorMessage: error.message || '알 수 없는 오류가 발생했습니다.',
    };
  }

  componentDidCatch(error: Error, errorInfo: unknown) {
    if (!this.shouldHandleError(error)) {
      return;
    }

    Sentry.captureException(error, {
      tags: {
        handler: 'ErrorBoundary',
        source: this.props.source,
        errorType: this.state.errorType,
      },
    });
  }

  handleClickReload = () => {
    window.location.reload();
  };

  handleClickGoBack = () => {
    window.history.back();
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  renderErrorContent() {
    switch (this.state.errorType) {
      case 'FORBIDDEN':
        return (
          <Box className="forbidden-wrapper">
            <Icon variant="cautionWhite" />
            <Description>접근 권한이 없습니다.</Description>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                mt: 2,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handleClickGoBack}
              >
                뒤로가기
              </Button>
            </Box>
          </Box>
        );
      default:
        return (
          <Box className="default-wrapper">
            <Icon variant="cautionWhite" />
            <Description>{this.state.errorMessage}</Description>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                mt: 2,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={this.handleClickGoBack}
              >
                뒤로가기
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleClickReload}
              >
                새로고침
              </Button>
            </Box>
          </Box>
        );
    }
  }

  render() {
    if (this.state.hasError) {
      return <Box sx={{ height: '100vh' }}>{this.renderErrorContent()}</Box>;
    }

    return this.props.children;
  }
}

export const ErrorBoundary = (props: Omit<ErrorBoundaryProps, 'snackbar'>) => {
  const snackbar = useSnackbarContext();
  return <ErrorBoundaryClass {...props} snackbar={snackbar} />;
};

export default ErrorBoundary;
