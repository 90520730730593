import { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Stack, Button } from './ClinicAdd.styled';
import PageNav from '~/components/commons/PageNav';
import { useApi } from '~/components/providers/ApiProvider';
import Addview from './Addview';
import { objectToFormData } from 'object-to-formdata';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import { format } from 'date-fns';
import { validatePassword } from '~/utils/validationUtils';
import { useHistory } from 'react-router-dom';

const defaultParams = {
  contactManager: '',
  contactTel: '',
  category: '병원',
  name: '',
  oldName: '',
  ownerName: '',
  clinicTel: '',
  employerNo: '',
  clinicNo: '',
  address: '',
  addressDetail: '',
  treatmentFields: [],
  bizStatus: '',
  bizItem: '',
  bizLicenseFile: '',
  contractDate: null,
  s3Size: 0,
  cms: {
    bank: '',
    bankAccount: '',
    autoTransferDay: 0,
    taxbillEmail: '',
  },
  additional: {
    agency: '',
    educationAgency: '',
    chartNoType: 'date',
  },
  callManagement: {
    type: 0,
  },
  crmManagementPlanPrice: 0,
  crmManagementPlanLimit: 0,
  penchartPlanPrice: 0,
  penchartPlanLimit: 0,
  signCertPlanPrice: 0,
  signCertPlanLimit: 0,
  number080PlanPrice: 0,
  number080PlanLimit: 0,
  insurancePlanPrice: 0,
  insurancePlanLimit: 0,
  userCount: 0,
  certificationUserCount: 0,
  rejectionPhoneNumber: '',
  memo: '',
  test: false,
  contactEmail: '',
  contactTempPassword: '',
  contractsFiles: [
    {
      contractId: null,
      contractFile: null,
      contractFileName: null,
      contractSignDate: null,
    },
  ],
  isCameraSync: false,
  cameraSyncPlanPrice: 0,
};

const ClinicAdd = () => {
  const snackbar = useSnackbarContext();
  const defaultParamsRef = useRef(defaultParams);
  const [value, setValue] = useState<any>(defaultParamsRef.current);
  const { clinicsApi } = useApi();
  const history = useHistory();
  const handleChangeValue = (newValue: any) => {
    setValue({ ...value, ...newValue });
  };

  const handleReset = () => {
    setValue(defaultParamsRef.current);
  };

  const validate = (password = '') => {
    const errorText = validatePassword(password);
    if (errorText) {
      throw new Error(errorText);
    }
  };

  const handleSave = async () => {
    try {
      validate(value.contactTempPassword);
    } catch (e: any) {
      snackbar.alert(e.message);
      return;
    }

    try {
      const params: any = {
        ...value,
        memo:
          value.memo !== ''
            ? JSON.stringify([
                {
                  timestamp: format(new Date(), 'yy-MM-dd'),
                  content: value.memo,
                },
              ])
            : '',
        owner: {
          email: value.contactEmail,
          password: value.contactTempPassword,
        },
        treatmentFields:
          value.treatmentFields.length > 0
            ? JSON.stringify(value.treatmentFields)
            : '[]',
        contractDate: value.contractDate
          ? format(new Date(value.contractDate), 'yyyy-MM-dd')
          : null,
      };
      delete params.contracts;
      delete params.contractsFiles;
      delete params.bizLicenseFile;

      const res: any = await clinicsApi.create(params);
      let formData: any = {};
      if (value?.contractsFiles.length > 0) {
        const fileList = value.contractsFiles
          .map((f: any) => {
            let obj: any = {
              contractId: f.contractId,
              contractFileName: f.contractFileName,
              contractSignDate: f.contractSignDate
                ? format(new Date(f.contractSignDate), 'yyyy-MM-dd')
                : undefined,
              contractFile: f.contractFile,
            };
            if (f.contractFile || f.contractSignDate) {
              obj.updateType = 'CREATE';
            }
            return obj;
          })
          .filter((f: any) => f.updateType);

        formData.contractFileList = fileList;
      }

      if (value?.bizLicenseFile) {
        formData.bizLicenseFile = value.bizLicenseFile;
      }

      if (formData?.contractFileList.length > 0 || formData?.bizLicenseFile) {
        await clinicsApi.uploadFiles(
          res.data.id,
          objectToFormData(
            {
              ...formData,
            },
            {
              indices: true,
            }
          )
        );
      }

      snackbar.success(`등록되었습니다.`);
      history.push(`/clinics/${res.data.id}`);
      handleReset();
    } catch (e: any) {
      console.error(e);
      if (e.name === 'Error') {
        snackbar.alert('오류가 발생했습니다. 잠시후 다시 시도해주세요');
      } else if (e.name === 'NO_PERMISSION') {
        snackbar.alert('접근 권한이 없습니다.');
      } else if (e.name !== null) {
        if (e.name === 'EMAIL_ALREADY_EXISTS') {
          snackbar.alert('이미 등록된 계정입니다. 확인 후 다시 진행해주세요');
        } else {
          snackbar.alert('모든값을 입력해주세요.');
        }
      } else {
        snackbar.alert(e.description);
      }
    }
  };

  return (
    <Box className="clinic-detail main-contents">
      <PageNav pages={['거래처 계정관리', '거래처 신규 등록']} />
      <Stack flexDirection={'row'} alignItems={'center'} className="mt-13">
        <Typography variant="h1">거래처 신규 등록</Typography>
        <Button
          variant="contained"
          className="edit-mode-btn"
          onClick={handleSave}
          disabled={
            value.contactEmail === '' ||
            value.contactTempPassword === '' ||
            value.contactManager === '' ||
            value.contactTel === '' ||
            value.name === '' ||
            value.ownerName === ''
          }
        >
          저장
        </Button>
      </Stack>
      <Addview value={value} onChangeValue={handleChangeValue} />
    </Box>
  );
};
export default observer(ClinicAdd);
