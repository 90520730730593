import styled from 'styled-components';
import {
  styled as muiStyled,
  Box as MuiBox,
  Typography as MuiTypography,
  Button as MuiButton,
  Stack as MuiStack,
  Link as MuiLink,
  switchClasses,
  formControlLabelClasses,
  stackClasses,
} from '@mui/material';

import { Checkbox as _Checkbox } from '~/components/commons/Checkbox';
import { TextareaAutosize as MuiTextareaAutosize } from '@mui/base';
import { ComboBox as _ComboBox } from '~/components/commons/ComboBox';
import { DropdownList as _DropdownList } from '~/components/commons/DropdownList_v1';
import { Tooltip as _Tooltip } from '~/components/commons/Tooltip';
import { TextField as _TextField } from '~/components/commons/TextField';
import { Label as _Label } from '~/components/commons/Label2';
import { Radio as _Radio } from '~/components/commons/Radio';
import { Switch as MuiSwitch } from '@mui/base';

const blue = {
  500: '#007FFF',
};

const grey = {
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
};

export const DropdownList = styled(_DropdownList)`
  height: 34px;
  .MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root {
    height: 34px;
  }
`;

export const CheckBox = muiStyled(_Checkbox)`
  background: unset;
  border: 0 none;
  box-shadow: none;
  width: 70px;

  &.ml-auto {
    margin-left: auto !important;
  }

  &.MuiFormControlLabel-root{
    display: flex;
    align-items: center;
    
    .MuiTypography-root {
      line-height: 12px;
    }
  }
`;

export const Switch = muiStyled(MuiSwitch)`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin: 10px;
  cursor: pointer;

  &.${switchClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchClasses.track} {
    background: ${({ theme }) =>
      theme.palette.mode === 'dark' ? grey[600] : grey[400]};
    border-radius: 16px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchClasses.thumb} {
    display: block;
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: 18px;
      top: 2px;
      background-color: #fff;
    }

    .${switchClasses.track} {
      background: ${blue[500]};
    }
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }`;

export const RadioInput = muiStyled(_Radio)``;

export const Link = muiStyled(MuiLink)`
  color: #008DDC !important;
`;

export const TextareaAutosize = muiStyled(MuiTextareaAutosize)`
  width: 100% !important;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.23);
  &:focus-visible {
    outline: solid 1px ${({ theme }) => theme.palette.primary.unoblue};
  }
`;

export const Stack = muiStyled(MuiStack)`
  width: 100%;
  
  &.contract-list-wrapper {
    height: 34px;
  }

  .MuiBox-root {
    align-items: center;
    &:not(:last-child) {
      border-right: 1px solid #dee2ec;
    }
  }

  .MuiStack-root {
    &:not(:last-child) {
      border-bottom: 1px solid #dee2ec;
    } 
  }

  &.border {
    border: 1px solid #dee2ec;
  }

  &.mt-10 {
    margin-top: 10px;
  }
  &.mt-13 {
    margin-top: 13px;
  }
`;

export const IconWrapper = muiStyled('div')`
  display: flex;
  margin-right: 8px;
`;

export const Box = muiStyled(MuiBox)`
  width: 100%;

  &.checkbox-wrapper {
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    display: flex !important;
    .checkbox {
      justify-content: flex-start;
    }
    .${formControlLabelClasses.root} {
      padding-left: 8px;
    }
  }

  &.checkbox-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  &.label {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    background: #f9fbff;
    min-height: 34px;
    width: 200px;
    flex-shrink: 0;
    display: flex;
    padding: 8.5px 12px;
    .required {
      color: #eb5757;
    }
  }
  &.value {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    background: #ffffff;
    min-height: 34px;
    display: block;
    position: relative;

    .MuiFormControl-root {
      background: #ffffff;
    }

    &.align-center {
      display: flex;
      align-items: center;
    }

    &.total-price {
      padding: 0 8px;
      color: #2C62F6;
    }

    .user-count {
      color: #2c62f6;
      text-decoration: underline;
      cursor: pointer;
    }

    .contracts-wrapper {
      display: flex;
      align-items: center;
      padding: 0px 8px;
      width: 219px;
      height: 100%;
      overflow: hidden;

      div {  
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .MuiInputBase-root {
      border-radius: 0;
      input {
        background: unset;
      }
      .MuiOutlinedInput-notchedOutline {
        border: 0 none;
      }
    }

    .daum-post-code {
      display: inline-table;
      position: absolute;
      height: 448px;
      left: 0;
      top: 34px;
      border: 1px solid #000000;
      z-index: 10;
    }

    &.chart-no-type {
      padding: 12px 13px;
      .warning-text {
        color: #EB5757;
      }
      .MuiBox-root {
        margin-top: 8px;
      }
    }
  }
  &.clinic-detail {
    width: 900px;
    padding-right: 40px;
  }
  &.memo {
    .memo-wrapper + .memo-btn {
      margin-top: 10px;
    }
  }
  &.MuiBox-root.memo-btn {
    padding: 0;
    display: block;
  }
`;

export const FormControl = muiStyled(MuiBox)`
  display: flex;
  flex-direction: row;
  padding: 3px;
  row-gap: 2px;
  width: 100%;
  gap: 8px;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 4px;
    display: inline-block;
    .required {
      color: #eb5757;
    }
  }
`;

export const ComboBox = muiStyled(_ComboBox)`
  height: 34px;
  .MuiOutlinedInput-root {
    height: 34px;
  }
`;

export const Label = muiStyled(_Label)`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
`;

export const Button = muiStyled(MuiButton)`
  border-radius: 0;
  min-width: 85px;
  font-size: 14px;
  &.btn-default {
    box-shadow: 0 2px 6px rgba(84, 72, 203, 0.2);
    background-color: #fff;
    color: #000;
  }
  &.btn-danger {
    background: #f7685b;
    box-shadow: none;
    &:disabled {
      color: #ffffff;
    }
  }
  &.edit-mode-btn {
    margin-left: auto;
    min-width: 72px;
    border-radius: 4px;
    height: 28px;
  }
  &.btn-info {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    min-width: 55px;
    height: 19px;
    border-radius: 2px;
  }

  &.file-upload-btn {
    margin-left: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    min-width: 57px;
    height: 25px;
    border-radius: 2px;
    padding: 0;
    color: #4a4e70;
    border: 1px solid #4a4e70;
  }
`;

export const FileInput = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

export const Typography = muiStyled(MuiTypography)`
  &.MuiTypography-h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
  }

  &.MuiTypography-h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    margin-top: 20px;

  }

  &.mt-0{
    margin-top:0;
  }
`;

export const DisabledButton = muiStyled('button')`
  margin-left: 10px;
  padding: 3px;
  font-weight: 400;
  font-size: 11px;
  border: 1px solid #bbbbbb;
  color: #bbbbbb;
  background-color: #fff;
`;

export const ModalWrapper = muiStyled('div')`
  width: 650px;
  max-height: calc(100vh - 40px);
  overflow: hidden;
`;

export const ModalBody = muiStyled('div')`
  padding: 0 30px;
`;

export const TextField = muiStyled(_TextField)`
  background-color: #f3f8ff;
  font-size: 14px;

  &.white-bg {
    background-color: #ffffff;
    input {
      background-color: #ffffff;
    }
  }
  .MuiInputBase-root {
    height: 34px;
  }

  input {
    background-color: #f3f8ff;
  }
`;

export const Text = styled('div')`
  margin: 5px 0;
  font-size: 11px;
`;

export const StackIconRow = muiStyled('div')(
  ({ theme }) => `
  display: flex;
  width: calc(100% + 42px);
  height: 34px;
  align-items: center;

  &:last-child {
    div {
      border-bottom: none !important;
    }
  }

  .label {
    padding: 0 12px;
  }

  & + & {
    .${stackClasses.root} {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: ${theme.palette.layout.line};
      }
    }
  }
`
);

export const StackIconWrapper = styled('div')`
  margin: 5px;
  cursor: pointer;
`;

export const DateFieldWrapper = styled('div')`
  width: 100%;
  padding: 0 8px;

  .react-datepicker-wrapper {
    width: 100%;
  }
  input {
    text-align: left;
    border: none !important;
  }
`;
