import { useMemo, useCallback } from 'react';
import { format } from 'date-fns';
import { color } from '~/theme';
import { agencies as agenciesOptions } from '~/hooks/constants';
import { stripEmpty } from '~/utils/objUtil';
import { Tooltip } from '~/components/commons/Tooltip';
import { QuestionMark } from '~/components/commons/QuestionMark';
import {
  DropdownList,
  Button,
  TextField,
  Box,
  Typography,
  SearchFilterRangeInput,
  CheckBox,
  FilterDateRange,
} from './SearchView.styled';

const TooltipComponent = () => {
  return (
    <Tooltip
      title={
        <span>
          월관리비 등록 기준을 초과하거나 미입력된 거래처가 조회됩니다.
          <br /> CRM사용 계정 | 펜차트 용량 | 전자서명 계정
        </span>
      }
    >
      <Box sx={{ marginLeft: '5px' }}>
        <QuestionMark size="medium" />
      </Box>
    </Tooltip>
  );
};

const uberSyncStatusOptions = [
  { label: '연동', value: 'true', id: 'true' },
  { label: '미연동', value: 'false', id: 'false' },
];

const cameraSyncStatusOptions = [
  { label: '사용', value: 'true', id: 'true' },
  { label: '미사용', value: 'false', id: 'false' },
];

const contractStatusOptions = [
  { label: '사용중', value: 'active', id: 'active' },
  { label: '해지', value: 'inactive', id: 'inactive' },
];

const accountTypesOptions = [
  { label: '테스트 계정', value: 'true', id: 'true' },
  { label: '병원 계정', value: 'false', id: 'false' },
];

const printLogOptions = [
  { label: '있음', value: 'true', id: 'true' },
  { label: '없음', value: 'true', id: 'false' },
];

type SearchViewProps = {
  width: number;
  onSubmit: () => void;
  onChange: (params: any) => void;
  value: any;
  onSearch: (params: any) => void;
};

export const SearchView = ({
  onSearch,
  value,
  onChange,
  onSubmit,
  width,
}: SearchViewProps) => {
  const searchFilterValues = useMemo(() => {
    return stripEmpty(
      {
        ...value,
        name: value?.name?.length < 2 ? '' : value.name,
        isOverLimit: value?.isOverLimit === true ? true : undefined,
      },
      {
        emptyString: true,
        emptyArray: true,
      }
    );
  }, [value]);

  const disabled = useMemo(() => {
    return Object.keys(searchFilterValues).length <= 3;
  }, [searchFilterValues]);

  const handleEnter = useCallback(() => {
    !disabled && onSubmit();
  }, [disabled, searchFilterValues]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: width ?? undefined,
        maxWidth: 1800,
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: `1px solid ${color.common.line}`,
            '& > *:not(:last-child)': {
              borderRight: `1px solid ${color.common.line}`,
            },
          }}
        >
          <Box width={210}>
            <TextField
              $type="search"
              value={value.id === 0 ? '' : String(value.id)}
              startAdornment="ID"
              placeholder="입력"
              onChange={(v: any) => {
                const newId = !isNaN(Number(v))
                  ? v
                  : value.id !== ''
                  ? value.id
                  : '';
                onChange({ id: newId });
              }}
              onEnter={handleEnter}
            />
          </Box>
          <Box
            sx={{
              width: 210,
              background: 'white',
            }}
          >
            <DropdownList
              value={contractStatusOptions.filter((o) => value.status === o.id)}
              options={contractStatusOptions}
              onChange={(v: any) => {
                onChange({ status: v?.id });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              startAdornment={<Typography>{'상태'}</Typography>}
              placeholder="전체"
            />
          </Box>
          <Box width={350}>
            <FilterDateRange
              label="등록일"
              startAt={value.createdAtStart}
              endAt={value.createdAtEnd}
              placeholder={'YYYY-MM-DD'}
              onChange={(start: string, end: string) => {
                onChange({
                  createdAtStart: start
                    ? format(new Date(start), 'yyyy-MM-dd')
                    : undefined,
                  createdAtEnd: end
                    ? format(new Date(end), 'yyyy-MM-dd')
                    : undefined,
                });
              }}
            />
          </Box>
          <Box width={210}>
            <TextField
              $type="search"
              value={value.name}
              startAdornment="거래처명"
              placeholder="2자리 이상 입력"
              onChange={(v: any) => onChange({ name: v })}
              onEnter={handleEnter}
            />
          </Box>
          <Box width={210}>
            <TextField
              $type="search"
              value={value.oldName}
              startAdornment="구 거래처명"
              placeholder="2자리 이상 입력"
              onChange={(v: any) => onChange({ oldName: v })}
              onEnter={handleEnter}
            />
          </Box>
          <Box width={210}>
            <TextField
              $type="search"
              value={value.employerNo}
              startAdornment="사업자번호"
              placeholder="3자리 이상 입력"
              onChange={(v: any) => onChange({ employerNo: v })}
              onEnter={handleEnter}
            />
          </Box>
        </Box>
        <Box className="checkbox-box">
          <CheckBox
            checked={value.isOverLimit ? value.isOverLimit : false}
            onChange={(v) => {
              onChange({ isOverLimit: v.target.checked });
            }}
            label="관리비 기준 초과"
          />
          <TooltipComponent />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            border: `1px solid ${color.common.line}`,
            '& > *:not(:last-child)': {
              borderRight: `1px solid ${color.common.line}`,
            },
          }}
        >
          <Box
            sx={{
              width: 210,
              background: 'white',
            }}
          >
            <DropdownList
              value={accountTypesOptions.filter((o) =>
                value.test?.includes(o.id)
              )}
              options={accountTypesOptions}
              onChange={(v: any) => {
                onChange({ test: v?.id });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              startAdornment={<Typography>{'계정유형'}</Typography>}
              placeholder="전체"
            />
          </Box>
          <Box
            sx={{
              width: 210,
              background: 'white',
            }}
          >
            <DropdownList
              value={agenciesOptions.filter((o) =>
                value.agency?.includes(o.value)
              )}
              options={agenciesOptions.map((item: any) => {
                item.label = item.key;
                item.id = item.value;
                return item;
              })}
              onChange={(v: any) => {
                onChange({ agency: v?.value });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              startAdornment={
                <Typography className="width-wide">{'계약대행사'}</Typography>
              }
              placeholder="전체"
            />
          </Box>
          <Box
            sx={{
              width: 320,
              background: 'white',
            }}
          >
            <SearchFilterRangeInput
              label="CRM 운영비"
              placeholder={'n'}
              endAdornment="원"
              startValue={value.crmManagementPlanPriceMin}
              endValue={value.crmManagementPlanPriceMax}
              onChange={(start: string, end: string) => {
                const crmManagementPlanPriceMin =
                  start && !isNaN(Number(start)) ? Number(start) : '';
                const crmManagementPlanPriceMax =
                  end && !isNaN(Number(end)) ? Number(end) : '';
                onChange({
                  crmManagementPlanPriceMin,
                  crmManagementPlanPriceMax,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          marginTop: 1,
          alignItems: 'center',
          background: 'white',
          border: `1px solid ${color.common.line}`,
          '& > *:not(:last-child)': {
            borderRight: `1px solid ${color.common.line}`,
          },
        }}
      >
        <Box
          sx={{
            width: 310,
            background: 'white',
          }}
        >
          <SearchFilterRangeInput
            className="account-count"
            label="사용자 사용 계정 수"
            placeholder={'n'}
            endAdornment="개"
            startValue={value.userCountMin}
            endValue={value.userCountMax}
            onChange={(start: string, end: string) => {
              const userCountMin =
                start && !isNaN(Number(start)) ? Number(start) : '';
              const userCountMax =
                end && !isNaN(Number(end)) ? Number(end) : '';
              onChange({
                userCountMin,
                userCountMax,
              });
            }}
          />
        </Box>
        <Box
          sx={{
            width: 310,
            background: 'white',
          }}
        >
          <SearchFilterRangeInput
            className="account-count"
            label="사용자 계약 계정 수"
            placeholder={'n'}
            endAdornment="개"
            startValue={value.crmManagementPlanLimitMin}
            endValue={value.crmManagementPlanLimitMax}
            onChange={(start: string, end: string) => {
              const crmManagementPlanLimitMin =
                start && !isNaN(Number(start)) ? Number(start) : '';
              const crmManagementPlanLimitMax =
                end && !isNaN(Number(end)) ? Number(end) : '';
              onChange({
                crmManagementPlanLimitMin,
                crmManagementPlanLimitMax,
              });
            }}
          />
        </Box>
        <Box
          sx={{
            width: 310,
            background: 'white',
          }}
        >
          <SearchFilterRangeInput
            label="펜차트 관리비"
            placeholder={'n'}
            endAdornment="원"
            startValue={value.penchartPlanPriceMin}
            endValue={value.penchartPlanPriceMax}
            onChange={(start: string, end: string) => {
              const penchartPlanPriceMin =
                start && !isNaN(Number(start)) ? Number(start) : '';
              const penchartPlanPriceMax =
                end && !isNaN(Number(end)) ? Number(end) : '';
              onChange({
                penchartPlanPriceMin,
                penchartPlanPriceMax,
              });
            }}
          />
        </Box>
        <Box
          sx={{
            width: 310,
            background: 'white',
          }}
        >
          <SearchFilterRangeInput
            className="pen-chart-account-bucket-size"
            label="펜차트 사용 용량"
            placeholder={'n'}
            endAdornment="GB"
            startValue={value.s3SizeMin}
            endValue={value.s3SizeMax}
            onChange={(start: string, end: string) => {
              const s3SizeMin =
                start && !isNaN(Number(start)) ? Number(start) : '';
              const s3SizeMax = end && !isNaN(Number(end)) ? Number(end) : '';
              onChange({
                s3SizeMin,
                s3SizeMax,
              });
            }}
          />
        </Box>
        <Box
          sx={{
            width: 310,
            background: 'white',
          }}
        >
          <SearchFilterRangeInput
            label="펜차트 계약 용량"
            placeholder={'n'}
            endAdornment="GB"
            startValue={value.penchartPlanLimitMin}
            endValue={value.penchartPlanLimitMax}
            onChange={(start: string, end: string) => {
              const penchartPlanLimitMin =
                start && !isNaN(Number(start)) ? Number(start) : '';
              const penchartPlanLimitMax =
                end && !isNaN(Number(end)) ? Number(end) : '';
              onChange({
                penchartPlanLimitMin,
                penchartPlanLimitMax,
              });
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            border: `1px solid ${color.common.line}`,
            '& > *:not(:last-child)': {
              borderRight: `1px solid ${color.common.line}`,
            },
          }}
        >
          <Box
            sx={{
              width: 310,
              background: 'white',
            }}
          >
            <SearchFilterRangeInput
              label="전자서명 관리비"
              placeholder={'n'}
              endAdornment="원"
              startValue={value.signCertPlanPriceMin}
              endValue={value.signCertPlanPriceMax}
              onChange={(start: string, end: string) => {
                const signCertPlanPriceMin =
                  start && !isNaN(Number(start)) ? Number(start) : '';
                const signCertPlanPriceMax =
                  end && !isNaN(Number(end)) ? Number(end) : '';
                onChange({
                  signCertPlanPriceMin,
                  signCertPlanPriceMax,
                });
              }}
            />
          </Box>
          <Box
            sx={{
              width: 310,
              background: 'white',
            }}
          >
            <SearchFilterRangeInput
              label="전자서명 사용 수"
              placeholder={'n'}
              endAdornment="개"
              startValue={value.certificationUserCountMin}
              endValue={value.certificationUserCountMax}
              onChange={(start: string, end: string) => {
                const certificationUserCountMin =
                  start && !isNaN(Number(start)) ? Number(start) : '';
                const certificationUserCountMax =
                  end && !isNaN(Number(end)) ? Number(end) : '';
                onChange({
                  certificationUserCountMin,
                  certificationUserCountMax,
                });
              }}
            />
          </Box>
          <Box
            sx={{
              width: 310,
              background: 'white',
            }}
          >
            <SearchFilterRangeInput
              label="080번호 관리비"
              placeholder={'n'}
              endAdornment="원"
              startValue={value.number080PlanPriceMin}
              endValue={value.number080PlanPriceMax}
              onChange={(start: string, end: string) => {
                const number080PlanPriceMin =
                  start && !isNaN(Number(start)) ? Number(start) : '';
                const number080PlanPriceMax =
                  end && !isNaN(Number(end)) ? Number(end) : '';
                onChange({
                  number080PlanPriceMin,
                  number080PlanPriceMax,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            border: `1px solid ${color.common.line}`,
            '& > *:not(:last-child)': {
              borderRight: `1px solid ${color.common.line}`,
            },
          }}
        >
          <Box
            sx={{
              width: 210,
              background: 'white',
            }}
          >
            <DropdownList
              value={uberSyncStatusOptions.filter((o) =>
                value.isUberSync?.includes(o.value)
              )}
              options={uberSyncStatusOptions}
              onChange={(v: any) => {
                onChange({ isUberSync: v?.value });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              startAdornment={<Typography>보험청구 연동</Typography>}
              placeholder="전체"
            />
          </Box>
          <Box
            sx={{
              width: 310,
              background: 'white',
            }}
          >
            <SearchFilterRangeInput
              className="w-120"
              label="보험청구 관리비"
              placeholder={'n'}
              endAdornment="원"
              startValue={value.insurancePlanPriceMin}
              endValue={value.insurancePlanPriceMax}
              onChange={(start: string, end: string) => {
                const insurancePlanPriceMin =
                  start && !isNaN(Number(start)) ? Number(start) : '';
                const insurancePlanPriceMax =
                  end && !isNaN(Number(end)) ? Number(end) : '';
                onChange({
                  insurancePlanPriceMin,
                  insurancePlanPriceMax,
                });
              }}
            />
          </Box>
          <Box
            sx={{
              width: 310,
              background: 'white',
            }}
          >
            <SearchFilterRangeInput
              label="보험청구 사용수"
              placeholder={'n'}
              endAdornment="개"
              startValue={value.insurancePlanLimitMin}
              endValue={value.insurancePlanLimitMax}
              onChange={(start: string, end: string) => {
                const insurancePlanLimitMin =
                  start && !isNaN(Number(start)) ? Number(start) : '';
                const insurancePlanLimitMax =
                  end && !isNaN(Number(end)) ? Number(end) : '';
                onChange({
                  insurancePlanLimitMin,
                  insurancePlanLimitMax,
                });
              }}
            />
          </Box>
          <Box
            sx={{
              width: 310,
              background: 'white',
            }}
          >
            <SearchFilterRangeInput
              label="문자잔액"
              placeholder={'n'}
              endAdornment="원"
              startValue={value.balanceMin}
              endValue={value.balanceMax}
              onChange={(start: string, end: string) => {
                const balanceMin =
                  start && !isNaN(Number(start)) ? Number(start) : '';
                const balanceMax =
                  end && !isNaN(Number(end)) ? Number(end) : '';
                onChange({
                  balanceMin,
                  balanceMax,
                });
              }}
            />
          </Box>
          <Box
            sx={{
              width: 310,
              background: 'white',
            }}
          >
            <SearchFilterRangeInput
              label="월 관리비 총액"
              placeholder={'n'}
              endAdornment="원"
              startValue={value.totalManagementPriceMin}
              endValue={value.totalManagementPriceMax}
              onChange={(start: string, end: string) => {
                const totalManagementPriceMin =
                  start && !isNaN(Number(start)) ? Number(start) : '';
                const totalManagementPriceMax =
                  end && !isNaN(Number(end)) ? Number(end) : '';
                onChange({
                  totalManagementPriceMin,
                  totalManagementPriceMax,
                });
              }}
            />
          </Box>
          <Box
            sx={{
              width: 210,
              background: 'white',
            }}
          >
            <DropdownList
              value={printLogOptions.filter((o) =>
                value.existPrintLog?.includes(o.id)
              )}
              options={printLogOptions}
              onChange={(v: any) => {
                onChange({ existPrintLog: v?.id });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              startAdornment={
                <Typography className="width-wide">네모닉 출력</Typography>
              }
              placeholder="전체"
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: 1 }}>
        <Box
          sx={{
            display: 'flex',

            alignItems: 'center',
            background: 'white',
            border: `1px solid ${color.common.line}`,
            '& > *:not(:last-child)': {
              borderRight: `1px solid ${color.common.line}`,
            },
          }}
        >
          <Box
            sx={{
              width: 210,
              background: 'white',
            }}
          >
            <DropdownList
              value={cameraSyncStatusOptions.filter((o) =>
                value.isCameraSync?.includes(o.value)
              )}
              options={cameraSyncStatusOptions}
              onChange={(v: any) => {
                onChange({ isCameraSync: v?.value });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              startAdornment={<Typography>카메라 연동 사용</Typography>}
              placeholder="전체"
            />
          </Box>
          <Box
            sx={{
              width: 310,
              background: 'white',
            }}
          >
            <SearchFilterRangeInput
              className="w-120"
              label="카메라 연동 관리비"
              placeholder={'n'}
              endAdornment="원"
              startValue={value.cameraSyncPlanPriceMin}
              endValue={value.cameraSyncPlanPriceMax}
              onChange={(start: string, end: string) => {
                const cameraSyncPlanPriceMin =
                  start && !isNaN(Number(start)) ? Number(start) : '';
                const cameraSyncPlanPriceMax =
                  end && !isNaN(Number(end)) ? Number(end) : '';
                onChange({
                  cameraSyncPlanPriceMin,
                  cameraSyncPlanPriceMax,
                });
              }}
            />
          </Box>
        </Box>
        <Button
          styled="outline"
          onClick={onSearch}
          className="search-btn"
          disabled={disabled}
        >
          조회
        </Button>
      </Box>
    </Box>
  );
};
