import {
  useEffect,
  useState,
  useCallback,
  useRef,
  ChangeEvent,
  useMemo,
} from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { format, isValid } from 'date-fns';
import $http from 'axios';
import DaumPostcode from 'react-daum-postcode';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useImperativeModal } from '~/components/providers/ImperativeModalProvider';
import { useApi } from '~/components/providers/ApiProvider';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import Icon from '~/components/commons/Icon';
import {
  banks,
  agencies,
  businessType,
  businessCondition,
  categories,
} from '~/hooks/constants';
import { toThousandCommas } from '~/filters';
import ModalUsedAccount from './ModalUsedAccount';
import { TreatmentItemSmsRepo } from '~/components/repo/TreatmentItemSmsRepo';
import { SessionSmsRepo } from '~/components/repo/SessionSmsRepo';
import CentrexEdit from './CentrexEdit';
import { DateField } from '~/components/fields/DateField';
import { ContractsFile } from '../ClinicAdd/ContractsFile';
import { ConfirmModal } from '~/components/modals/ConfirmModal';
import {
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  IconWrapper,
  FileInput,
  RadioInput,
  Switch,
  CheckBox,
  Link,
  DropdownList,
  ComboBox,
  Text,
  DateFieldWrapper,
} from './EditView.styled';

type ClinicEditViewProps = {
  onChangeValue: (value: any) => void;
  data: any;
  value: any;
};

const ClinicEditView = (props: ClinicEditViewProps) => {
  const { onChangeValue, data, value } = props;
  const fileRef: any = useRef();
  const { clinicsApi } = useApi();
  const snackbar = useSnackbarContext();
  const imperativeModal = useImperativeModal();
  const [isOpenPost, setIsOpenPost] = useState<boolean>(false);
  const [treatmentFields, setTreatmentFields] = useState<
    { label: string; value: string; id: number }[]
  >([]);
  const [processingTreatmentItemSms, setProcessingTreatmentItemSms] =
    useState<boolean>(false);
  const [processingSessionSms, setProcessingSessionSms] =
    useState<boolean>(false);

  const businessConditionOptions = useMemo(
    () =>
      businessCondition.map((condition) => ({
        value: condition.key,
        label: condition.key,
      })),
    [businessCondition]
  );

  const businessTypeOptions = useMemo(
    () => businessType.map((type) => ({ value: type.key, label: type.key })),
    [businessType]
  );

  const banksOptions = useMemo(
    () => banks.map((bank) => ({ value: bank.value, label: bank.key })),
    [banks]
  );

  const agenciesOptions = useMemo(
    () =>
      agencies.map((agency) => ({ value: agency.value, label: agency.key })),
    []
  );

  const treatmentFieldsCallApi = useCallback(async () => {
    const resp = await $http.get('/clinics/treatment_fields');
    const fields = resp.data.map((v: { id: number; name: string }) => {
      return {
        label: v.name,
        value: v.name,
        id: v.id,
      };
    });
    setTreatmentFields(fields);
  }, []);

  useEffect(() => {
    treatmentFieldsCallApi();
  }, []);

  useEffect(() => {
    if (
      data?.callManagement !== undefined &&
      value.callManagement.type !== -1
    ) {
      if (value.callManagement.type !== data.callManagement.type) {
        saveCallManagementType(value.callManagement.type);
      }
    }
  }, [value.callManagement.type, data?.callManagement?.type]);

  const saveCallManagementType = async (type: number) => {
    try {
      await clinicsApi.update(data.id, {
        callManagement: {
          ...data.callManagement,
          type,
        },
      });
      runInAction(() => {
        data.callManagement.type = type;
      });
      snackbar.success(`수정되었습니다.`);
    } catch (error) {
      snackbar.alert('오류가 발생했습니다. 잠시후 다시 시도해주세요');
      onChangeValue({
        callManagement: {
          ...value.callManagement,
          type: value.callManagement.type === 0 ? 1 : 0,
        },
      });
    }
  };

  const handleChangeOpenPost = (e: Event) => {
    e.stopPropagation();
    setIsOpenPost(!isOpenPost);
  };

  const handleCompletePost = (data: any) => {
    let fullAddr =
      data.userSelectedType === 'R' ? data.roadAddress : data.jibunAddress;
    let extraAddr = '';

    if (data.userSelectedType === 'R') {
      if (data.bname !== '') {
        extraAddr += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddr +=
          extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
    }

    onChangeValue({ address: fullAddr });
    setIsOpenPost(false);
  };

  const handleChangeImage = (e: any, type: string) => {
    let target = e.target;
    if (target.files[0] === undefined) {
      return;
    }

    const { size, name } = e.target.files[0];
    const fileType = name.split('.').pop();
    const confirmType = ['jpg', 'JPG', 'png', 'PNG', 'jpeg', 'pdf', 'PDF'];
    // 10Mb
    const confirmSize = 10;
    if (size / 1024 / 1024 > confirmSize) {
      snackbar.alert('10MB 이하로만 첨부가능합니다.');
      return;
    }

    if (confirmType.indexOf(fileType) === -1) {
      snackbar.alert('jpg 또는 PNG 또는 PDF 파일만 첨부가능합니다.');
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event: any) {
      let newValue: any = {};
      const file = target.files[0];

      if (file.type === 'application/pdf') {
        newValue[`${type}`] = file;
        onChangeValue({ ...newValue });

        return;
      }

      const img: any = new Image();
      img.src = event.target.result;

      img.onload = function () {
        let newValue: any = {};
        const file = target.files[0];
        newValue[`${type}`] = file;

        onChangeValue({ ...newValue });
      };
    };
    reader.readAsDataURL(target.files[0]);
  };

  const handleOpenUsedAccountModal = async () => {
    await imperativeModal.open((close) => {
      return (
        <ModalUsedAccount
          clinicId={data.id}
          title={'사용중인 계정'}
          onClose={close}
        />
      );
    });
  };

  const handleClickUpdateTreatmentItemSms = async () => {
    const confirm = await imperativeModal.open((close) => (
      <ConfirmModal
        variant="primary"
        onConfirm={() => close(true)}
        onCancel={() => close(false)}
      >
        주기별 자동 문자 발송을 갱신하시겠습니까?
      </ConfirmModal>
    ));

    if (confirm) {
      if (processingTreatmentItemSms) return;
      try {
        setProcessingTreatmentItemSms(true);

        const treatmentItemRepo = new TreatmentItemSmsRepo();
        await treatmentItemRepo.migrate(data.id);
      } finally {
        setProcessingTreatmentItemSms(false);
      }
    }
  };

  const handleClickUpdateSessionSms = async () => {
    const confirm = await imperativeModal.open((close) => (
      <ConfirmModal
        variant="primary"
        onConfirm={() => close(true)}
        onCancel={() => close(false)}
      >
        처리되지 않은 자동 예약 문자를 발송하시겠습니까?
      </ConfirmModal>
    ));

    if (confirm) {
      if (processingSessionSms) return;
      try {
        setProcessingSessionSms(true);
        const repo = new SessionSmsRepo();
        await repo.refresh(data.id);
      } finally {
        setProcessingSessionSms(false);
      }
    }
  };

  return (
    <>
      <Box className="checkbox-box">
        <Typography variant="h2" className="mt-0">
          계정 정보
        </Typography>
        <CheckBox
          checked={value.test}
          onChange={(v: any) => {
            onChangeValue({ test: v.target.checked });
          }}
          label="테스트 계정"
          className="checkbox ml-auto"
        />
      </Box>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack flexDirection={'row'}>
          <Box className="label dim ">
            아이디(이메일) <span className="required">*</span>
          </Box>
          <Box className="value dim p-8 align-center">
            <span className="multi-line">{data?.contactEmail || '-'}</span>
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label ">
            담당자 이름 <span className="required">*</span>
          </Box>
          <Box className="value w-200">
            <TextField
              value={value.contactManager || ''}
              onChange={(v: string) => {
                onChangeValue({ contactManager: v });
              }}
              placeholder="담당자 이름을 입력하세요"
            />
          </Box>
          <Box className="label">
            전화번호 <span className="required">*</span>
          </Box>
          <Box className="value">
            <TextField
              value={value.contactTel || ''}
              onChange={(v: string) => {
                onChangeValue({ contactTel: v });
              }}
              placeholder="전화번호를 입력하세요"
            />
          </Box>
        </Stack>
      </Stack>
      <Typography variant="h2">사업자 정보</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack flexDirection={'row'}>
          <Box className="label">
            거래처명(병원명) <span className="required">*</span>
          </Box>
          <Box className="value">
            <TextField
              value={value.name || ''}
              onChange={(v: string) => {
                onChangeValue({ name: v });
              }}
              placeholder="거래처명을 입력하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">구 거래처명</Box>
          <Box className="value">
            <TextField
              value={value.oldName || ''}
              onChange={(v: string) => {
                onChangeValue({ oldName: v });
              }}
              placeholder="구 거래처명을 입력하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">
            대표자 <span className="required">*</span>
          </Box>
          <Box className="value">
            <TextField
              value={value.ownerName || ''}
              onChange={(v: string) => {
                onChangeValue({ ownerName: v });
              }}
              placeholder="대표자명을 입력하세요"
            />
          </Box>
          <Box className="label">대표번호</Box>
          <Box className="value">
            <TextField
              value={value.clinicTel || ''}
              onChange={(v: string) => {
                onChangeValue({ clinicTel: v });
              }}
              placeholder="대표자번호를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">사업자등록번호</Box>
          <Box className="value">
            <TextField
              value={value.employerNo || ''}
              onChange={(v: string) => {
                onChangeValue({ employerNo: v });
              }}
              placeholder="사업자등록번호를 입력하세요"
            />
          </Box>
          <Box className="label">요양기관번호</Box>
          <Box className="value">
            <TextField
              value={value.clinicNo || ''}
              onChange={(v: string) => {
                onChangeValue({ clinicNo: v });
              }}
              placeholder="요양기관번호를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">주소</Box>
          <Box className="value">
            <TextField
              readOnly
              value={value.address || ''}
              onClick={(e: Event) => handleChangeOpenPost(e)}
              placeholder="주소를 검색하세요"
              onChange={(v: string) => {
                onChangeValue({ address: v });
              }}
              endAdornment={
                <IconWrapper>
                  <Icon variant="search" />
                </IconWrapper>
              }
            />
            {isOpenPost ? (
              <ClickAwayListener
                onClickAway={() => {
                  setIsOpenPost(false);
                }}
              >
                <DaumPostcode
                  className="daum-post-code"
                  autoClose={true}
                  onComplete={handleCompletePost}
                />
              </ClickAwayListener>
            ) : null}
          </Box>
          <Box className="label">상세 주소</Box>
          <Box className="value">
            <TextField
              value={value.addressDetail || ''}
              onChange={(v: string) => {
                onChangeValue({ addressDetail: v });
              }}
              placeholder="상세주소를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">구분</Box>
          <Box className="value">
            <DropdownList
              value={categories
                .map((item) => {
                  return {
                    label: item,
                    value: item,
                  };
                })
                .filter((o) => {
                  return value.category === o.value;
                })}
              options={categories.map((item) => {
                return {
                  label: item,
                  value: item,
                };
              })}
              onChange={(v: { value: string; label: string }) => {
                onChangeValue({ category: v?.value || '병원' });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="전체"
            />
          </Box>
          <Box className="label">주요 시/수술 분야</Box>
          <Box className="value">
            <ComboBox
              unlimit={true}
              multiple={true}
              variant="search"
              value={
                treatmentFields.filter((o: any) => {
                  return (value.treatmentFields || [])?.includes(o.value);
                }) || []
              }
              options={treatmentFields}
              onChange={(v: any) => {
                onChangeValue({
                  treatmentFields: v ? v.map((item: any) => item.value) : [],
                });
              }}
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="주요 시/수술 분야를 선택하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">업태</Box>
          <Box className="value">
            <DropdownList
              value={businessConditionOptions.filter((o: { value: string }) => {
                return value.bizStatus === o.value;
              })}
              options={businessConditionOptions}
              onChange={(v: { value: string; label: string }) => {
                onChangeValue({ bizStatus: v?.value });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="업태를 선택하세요"
            />
          </Box>
          <Box className="label">업종</Box>
          <Box className="value">
            <DropdownList
              value={businessTypeOptions.filter((o: { value: string }) => {
                return value.bizItem === o.value;
              })}
              options={businessTypeOptions}
              onChange={(v: { value: string; label: string }) => {
                onChangeValue({ bizItem: v?.value });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="업종을 선택하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label" sx={{ padding: '0 12px !important' }}>
            사업자등록증
            <Button
              variant="outlined"
              className="file-upload-btn"
              onClick={() => fileRef.current.click()}
            >
              첨부하기
            </Button>
            <FileInput
              ref={fileRef}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeImage(e, 'bizLicenseFile')
              }
            />
          </Box>
          <Box className="value p-8">
            {value?.bizLicenseFile ? (
              value.bizLicenseFile?.name || ''
            ) : data?.bizLicenseUrl ? (
              <span className="biz-license-wrapper">
                <Link href={data.bizLicenseUrl} target="_blank">
                  {data.bizLicenseUrl}
                </Link>
              </span>
            ) : (
              ''
            )}
          </Box>
        </Stack>
      </Stack>
      <Text>∙ 사업자등록증 첨부는 JPG, PNG, PDF 파일만 가능합니다.</Text>
      <Typography variant="h2">CMS 자동이체 정보</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack flexDirection={'row'}>
          <Box className="label">은행</Box>
          <Box className="value">
            <DropdownList
              value={banksOptions.filter((o) => {
                return value.cms?.bank === o.value;
              })}
              options={banksOptions}
              onChange={(v: { value: string }) => {
                onChangeValue({
                  cms: {
                    ...value.cms,
                    bank: v?.value,
                  },
                });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="은행을 선택하세요"
            />
          </Box>
          <Box className="label">계좌번호</Box>
          <Box className="value">
            <TextField
              value={value.cms?.bankAccount || ''}
              onChange={(v: string) => {
                onChangeValue({
                  cms: {
                    ...value.cms,
                    bankAccount: v,
                  },
                });
              }}
              placeholder="계좌번호를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">자동이체(매월)</Box>
          <Box className="value">
            <TextField
              value={
                value.cms?.autoTransferDay === 0
                  ? ''
                  : String(value.cms?.autoTransferDay)
              }
              onChange={(v: string) => {
                onChangeValue({
                  cms: {
                    ...value.cms,
                    autoTransferDay: !isNaN(Number(v)) ? Number(v) : 0,
                  },
                });
              }}
              placeholder="자동이체 계좌번호를 입력하세요"
            />
          </Box>
          <Box className="label">세금계산서 발행메일</Box>
          <Box className="value">
            <TextField
              value={value.cms?.taxbillEmail || ''}
              onChange={(v: string) => {
                onChangeValue({
                  cms: {
                    ...value.cms,
                    taxbillEmail: v,
                  },
                });
              }}
              placeholder="세금계산서 발행메일을 입력하세요"
            />
          </Box>
        </Stack>
      </Stack>
      <Typography variant="h2">대행사 및 기타 정보</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack flexDirection={'row'}>
          <Box className="label">계약 대행사</Box>
          <Box className="value">
            <DropdownList
              value={agenciesOptions.filter((o: any) => {
                return value.additional.agency === o.value;
              })}
              options={agenciesOptions}
              onChange={(v: { value: string; label: string }) => {
                onChangeValue({
                  additional: {
                    ...value.additional,
                    agency: v?.value,
                  },
                });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="계약 대행사를 선택하세요"
            />
          </Box>
          <Box className="label">교육 대행사</Box>
          <Box className="value">
            <DropdownList
              value={agenciesOptions.filter((o) => {
                return value.additional.educationAgency === o.value;
              })}
              options={agenciesOptions}
              onChange={(v: { value: string; label: string }) => {
                onChangeValue({
                  additional: {
                    ...value.additional,
                    educationAgency: v?.value,
                  },
                });
              }}
              variant="search"
              getOptionLabel={(o: any) => {
                if (o[0]) {
                  return o[0]?.label ? o[0].label : '';
                } else {
                  return o ? (o?.label ? o.label : '') : '';
                }
              }}
              placeholder="교육 대행사를 선택하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">자동 차트번호 타입</Box>
          <Box className="value chart-no-type">
            <span className="warning-text">
              ※ 이미 사용중인 거래처의 경우 차트번호 타입 변경은 불가합니다.
            </span>
            <Box>
              <RadioInput
                value={value.additional.chartNoType}
                disabled={true}
                options={[
                  {
                    label: '날짜형 (ex:) 20190101001, 20190101002)',
                    value: 'date',
                  },
                  { label: '숫자형 (ex:) 00000001, 00000002)', value: 'num' },
                ]}
                onChange={(v) => {
                  onChangeValue({
                    additional: {
                      ...value.additional,
                      chartNoType: v,
                    },
                  });
                }}
              />
            </Box>
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">센트릭스 사용여부</Box>
          <Box className="value">
            <Switch
              checked={
                value.callManagement.type !== -1 &&
                value.callManagement.type !== 0
              }
              onChange={(e) => {
                onChangeValue({
                  callManagement: {
                    ...value.callManagement,
                    type: e.target.checked === true ? 1 : 0,
                  },
                });
              }}
            />
          </Box>
        </Stack>
      </Stack>
      {value.callManagement.type !== -1 && value.callManagement.type !== 0 && (
        <>
          <Typography variant="h2">회선 정보</Typography>
          <CentrexEdit data={data} value={value} />
        </>
      )}

      <Typography variant="h2">문자 업데이트 설정</Typography>
      <Stack flexDirection={'row'} className="mt-10 outlined-btn-wrapper">
        <Button
          variant="outlined"
          disabled={processingTreatmentItemSms}
          onClick={handleClickUpdateTreatmentItemSms}
        >
          주기별 문자 업데이트
        </Button>
        <Button
          variant="outlined"
          disabled={processingSessionSms}
          onClick={handleClickUpdateSessionSms}
        >
          미발송 자동 문자 업데이트
        </Button>
      </Stack>
      <Typography variant="h2">월 관리비 정보(VAT별도)</Typography>
      <Stack flexDirection={'column'} className="mt-10 border">
        <Stack flexDirection={'row'}>
          <Box className="label">CRM 운영비</Box>
          <Box className="value">
            <TextField
              value={value.crmManagementPlanPrice || 0}
              onChange={(v: string) => {
                onChangeValue({
                  crmManagementPlanPrice: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="CRM 운영비를 입력하세요"
            />
          </Box>
          <Box className="label">사용자 계정 수</Box>
          <Box className="value">
            <TextField
              value={value.crmManagementPlanLimit || 0}
              onChange={(v: string) => {
                onChangeValue({
                  crmManagementPlanLimit: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="사용자 계정 수를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">펜차트 관리비</Box>
          <Box className="value">
            <TextField
              value={value.penchartPlanPrice || 0}
              onChange={(v: string) => {
                onChangeValue({
                  penchartPlanPrice: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="CRM 운영비를 입력하세요"
            />
          </Box>
          <Box className="label">펜차트 용량(GB)</Box>
          <Box className="value">
            <TextField
              value={value.penchartPlanLimit || 0}
              onChange={(v: string) => {
                onChangeValue({
                  penchartPlanLimit: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="사용자 계정 수를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">전자서명</Box>
          <Box className="value">
            <TextField
              value={value.signCertPlanPrice || 0}
              onChange={(v: string) => {
                onChangeValue({
                  signCertPlanPrice: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="전자서명 비용을 입력하세요"
            />
          </Box>
          <Box className="label">전자서명 사용 수</Box>
          <Box className="value">
            <TextField
              value={value.signCertPlanLimit || 0}
              onChange={(v: string) => {
                onChangeValue({
                  signCertPlanLimit: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="전자서명 사용 수를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">080 번호사용</Box>
          <Box className="value">
            <TextField
              value={value.number080PlanPrice || 0}
              onChange={(v: string) => {
                onChangeValue({
                  number080PlanPrice: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="080 번호사용 비용을 입력하세요"
            />
          </Box>
          <Box className="label">080 수신거부 번호</Box>
          <Box className="value">
            <TextField
              value={value.rejectionPhoneNumber || 0}
              onChange={(v: string) => {
                onChangeValue({
                  rejectionPhoneNumber: v,
                });
              }}
              placeholder="080 수신거부 번호를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">보험청구 연동여부</Box>
          <Box className="value checkbox-wrapper">
            <CheckBox
              checked={value.isUberSync === true}
              onChange={(v: any) => {
                onChangeValue({ isUberSync: v.target.checked });
              }}
              label="연동"
              className="checkbox"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">보험청구</Box>
          <Box className="value">
            <TextField
              value={value.insurancePlanPrice || 0}
              onChange={(v: string) => {
                onChangeValue({
                  insurancePlanPrice: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="보험청구 관리비를 입력하세요"
            />
          </Box>
          <Box className="label">보험청구 사용 수</Box>
          <Box className="value">
            <TextField
              value={value.insurancePlanLimit || 0}
              onChange={(v: string) => {
                onChangeValue({
                  insurancePlanLimit: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="보험청구 사용수를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">카메라 연동 사용여부</Box>
          <Box className="value checkbox-wrapper">
            <CheckBox
              checked={value.isCameraSync === true}
              onChange={(v: any) => {
                onChangeValue({ isCameraSync: v.target.checked });
              }}
              label="사용"
              className="checkbox"
            />
          </Box>
          <Box className="label">카메라 연동 관리비</Box>
          <Box className="value">
            <TextField
              value={value.cameraSyncPlanPrice || 0}
              onChange={(v: string) => {
                onChangeValue({
                  cameraSyncPlanPrice: !isNaN(Number(v)) ? Number(v) : 0,
                });
              }}
              placeholder="카메라 연동 관리비를 입력하세요"
            />
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">총 금액</Box>
          <Box className="value align-center total-price">
            {toThousandCommas(
              String(
                value.penchartPlanPrice +
                  value.crmManagementPlanPrice +
                  value.signCertPlanPrice +
                  value.number080PlanPrice +
                  value.insurancePlanPrice
              )
            )}
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">최초 계약일</Box>
          <Box className="value">
            <DateFieldWrapper>
              <DateField
                placeholder="최초 계약일을 선택하세요"
                value={value.contractDate ? new Date(value.contractDate) : null}
                onChange={(v) => onChangeValue({ contractDate: v })}
              />
            </DateFieldWrapper>
          </Box>
        </Stack>
        {value?.contractsFiles
          ? value?.contractsFiles.map((item: any, index: number) => {
              return (
                <ContractsFile
                  key={index}
                  value={value}
                  item={item}
                  index={index}
                  onChangeValue={onChangeValue}
                />
              );
            })
          : ''}
      </Stack>
      <Text>
        ∙ 계약서 첨부는 최대 10건 까지 JPG, PNG, PDF 파일만 가능합니다.
      </Text>
      <Typography variant="h2">메모</Typography>
      <Stack flexDirection={'column'} className="mt-10 border dim">
        <Stack flexDirection={'row'}>
          <Box className="label">메모</Box>
          <Box className="value p-8">
            <Box className={`memo`}>
              {value.memo !== '' &&
              value.memo !== null &&
              value.memo !== '[]' ? (
                <div className="memo-wrapper">
                  {JSON.parse(value.memo).map(
                    (
                      memoItem: { content: string; timestamp: string },
                      idx: number
                    ) => {
                      return (
                        <div key={idx}>
                          {memoItem.content} ({memoItem.timestamp})
                        </div>
                      );
                    }
                  )}
                </div>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Stack>
      </Stack>
      <Typography variant="h2">사용현황</Typography>
      <Stack flexDirection={'column'} className="mt-10 border dim">
        <Stack flexDirection={'row'}>
          <Box className="label">전자서명</Box>
          <Box className="value p-8">
            {toThousandCommas(String(data?.certificationUserCount || 0))}
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">간편접수</Box>
          <Box className="value p-8">
            {toThousandCommas(String(data?.registrationUserCount || 0))}
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">계정수(사용중)</Box>
          <Box className="value p-8">
            {data?.userCount ? (
              <span className="user-count" onClick={handleOpenUsedAccountModal}>
                {toThousandCommas(String(data?.userCount || 0))}
              </span>
            ) : (
              '-'
            )}
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">펜차트 용량(GB)</Box>
          <Box className="value p-8">
            {toThousandCommas(String(data?.s3Size || 0))}
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">문자 잔액(원)</Box>
          <Box className="value p-8">
            {toThousandCommas(
              String(
                (!isNaN(Number(data?.balance?.amount))
                  ? Number(data?.balance?.amount)
                  : 0) +
                  (!isNaN(Number(data?.balance?.freeAmount))
                    ? Number(data?.balance?.freeAmount)
                    : 0)
              )
            )}
            {` (유료: ${toThousandCommas(
              String(data?.balance?.amount)
            )} / 무료: ${toThousandCommas(String(data?.balance?.freeAmount))})`}
          </Box>
        </Stack>
        <Stack flexDirection={'row'}>
          <Box className="label">최근접속일시</Box>
          <Box className="value p-8">
            {isValid(data?.customerConvertType)
              ? format(
                  new Date(data?.customerConvertType),
                  'yyyy/MM/dd hh:MM:ss'
                )
              : '-'}
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
export default observer(ClinicEditView);
