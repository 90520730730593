import { styled } from '@mui/material';
import {
  Box as MuiBox,
  Typography as MuiTypography,
  Button as MuiButton,
  Stack as MuiStack,
  Link as MuiLink,
} from '@mui/material';

import { TextareaAutosize as MuiTextareaAutosize } from '@mui/base';
import { ComboBox as _ComboBox } from '~/components/commons/ComboBox';
import { Tooltip as _Tooltip } from '~/components/commons/Tooltip';
import { TextField as _TextField } from '~/components/commons/TextField';
import { Label as _Label } from '~/components/commons/Label2';
import { theme } from '~/theme';

export const Link = styled(MuiLink)`
  color: #008ddc !important;
`;

export const TextareaAutosize = styled(MuiTextareaAutosize)`
  width: 100% !important;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.23);
  &:focus-visible {
    outline: solid 1px ${({ theme }) => theme.palette.primary.unoblue};
  }
`;

export const Stack = styled(MuiStack)`
  .MuiBox-root {
    padding: 8.5px 12px;
    align-items: center;
    &:not(:last-child) {
      border-right: 1px solid #dee2ec;
    }
  }

  .MuiStack-root {
    &:not(:last-child) {
      border-bottom: 1px solid #dee2ec;
    }
  }

  &.disabled {
    color: ${theme.palette.grey['500']};
  }
  &.border {
    border: 1px solid #dee2ec;
  }

  &.mt-10 {
    margin-top: 10px;
  }
  &.mt-13 {
    margin-top: 13px;
  }

  .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .contracts-wrapper {
    display: flex;
    align-items: center;
    width: 200px;
    height: 100%;
    overflow: hidden;
    div {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const Box = styled(MuiBox)`
  width: 100%;

  &.checkbox-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 838px;
  }

  &.label {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    background: #f9fbff;
    min-height: 34px;
    width: 200px;
    flex-shrink: 0;
    display: flex;
    padding: 8.5px 12px;
    .required {
      color: #eb5757;
    }
    &.dim {
      background: #f1f1f1;
    }
  }
  &.value {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    background: #ffffff;
    min-height: 34px;
    display: block;
    position: relative;
    &.dim {
      background: #f1f1f1;
    }

    &.align-center {
      display: flex;
      align-items: center;
    }

    &.total-price {
      padding: 0 8px;
      color: #2c62f6;
    }

    &.w-220 {
      width: 220px;
      flex-shrink: 0;
    }

    .MuiFormControl-root {
      background: #ffffff;
    }

    .multi-line {
      display: inline-block;
      width: 100%;
      white-space: normal;
      word-wrap: break-word;
    }

    .user-count {
      color: #2c62f6;
      text-decoration: underline;
      cursor: pointer;
    }

    .contract-wrapper,
    .biz-license-wrapper {
      display: block;
      & + .contract-wrapper {
        margin-top: 8px;
      }
    }

    .MuiInputBase-root {
      border-radius: 0;
      input {
        background: unset;
      }
      .MuiOutlinedInput-notchedOutline {
        border: 0 none;
      }
    }

    .daum-post-code {
      display: inline-table;
      position: absolute;
      height: 448px;
      left: 0;
      top: 34px;
      border: 1px solid #000000;
      z-index: 10;
    }

    &.chart-no-type {
      padding: 12px 13px;
      .warning-text {
        color: #eb5757;
      }
      .MuiBox-root {
        margin-top: 8px;
      }
    }

    &.disabled-text {
      color: ${theme.palette.grey['400']};
    }
  }

  &.clinic-detail {
    width: 900px;
    padding-right: 40px;
  }
  &.memo {
    .memo-wrapper + .memo-btn {
      margin-top: 10px;
    }
  }
  &.MuiBox-root.memo-btn {
    padding: 0;
    display: block;
  }
  &.no-data {
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    color: ${theme.palette.bluegrey['600']};
  }

  &.no-contents {
    background: none !important;
  }
`;

export const FormControl = styled(MuiBox)`
  display: flex;
  flex-direction: row;
  padding: 3px;
  row-gap: 2px;
  width: 100%;
  gap: 8px;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 4px;
    display: inline-block;
    .required {
      color: #eb5757;
    }
  }
`;

export const ComboBox = styled(_ComboBox)`
  height: 34px;
  .MuiOutlinedInput-root {
    height: 34px;
  }
`;

export const Label = styled(_Label)`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
`;

export const Button = styled(MuiButton)`
  border-radius: 0;
  min-width: 85px;
  font-size: 14px;
  &.btn-default {
    box-shadow: 0 2px 6px rgba(84, 72, 203, 0.2);
    background-color: #fff;
    color: #000;
  }
  &.btn-danger {
    background: #f7685b;
    box-shadow: none;
    &:disabled {
      color: #ffffff;
    }
  }
  &.edit-mode-btn {
    margin-left: auto;
    min-width: 72px;
    border-radius: 4px;
    height: 28px;
  }
  &.btn-info {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 11px;
    min-width: 55px;
    height: 19px;
    border-radius: 2px;
  }
  &.mt-15 {
    margin-top: 15px;
  }
`;

export const Typography = styled(MuiTypography)`
  &.MuiTypography-h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
  }

  &.MuiTypography-h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    margin-top: 20px;
  }
`;

export const DisabledButton = styled('button')`
  margin-left: 10px;
  padding: 3px;
  font-weight: 400;
  font-size: 11px;
  border: 1px solid #bbbbbb;
  color: #bbbbbb;
  background-color: #fff;
`;

export const ModalWrapper = styled('div')`
  width: 650px;
  max-height: calc(100vh - 40px);
  overflow: hidden;
`;

export const ModalBody = styled('div')`
  padding: 0 30px;
`;

export const TextField = styled(_TextField)`
  background-color: #f3f8ff;
  font-size: 14px;

  &.white-bg {
    background-color: #ffffff;
    input {
      background-color: #ffffff;
    }
  }
  .MuiInputBase-root {
    height: 32px;
  }

  input {
    background-color: #f3f8ff;
  }
`;
