import { useEffect, Component, ReactNode } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import 'firebase/remote-config';
import { observer } from 'mobx-react';
import routes from './routes';
import { runInAction } from 'mobx';
import '~/assets/styles/routes/MainContents.css';
import AuthedRouter from './AuthedRouter';
import { useApp } from '~/hooks/useApp';
import { useSnackbarContext } from '~/components/providers/SnackbarProvider';
import {
  useAuth as useProviderAuth,
  AuthError,
} from '~/components/providers/AuthProvider';

const RouterView = () => {
  const app = useApp();
  const auth_v2 = useProviderAuth();
  const snackbar = useSnackbarContext();

  app.$router = useHistory();

  useEffect(() => {
    if (
      auth_v2.error === AuthError.UNAUTHORIZED ||
      auth_v2.error === AuthError.ACCESS_DENIED
    ) {
      snackbar.alert('사용자 인증 세션이 만료되었습니다.');
      auth_v2.signout();
    }
  }, [auth_v2.error]);

  useEffect(() => {
    if (app.$loading) {
      runInAction(() => {
        app.$modal.loading({
          color: '#0060FF',
          message: '잠시만 기다려주세요',
          barType: 'spin',
          type: 'LOADING',
        });
      });
    } else {
      runInAction(() => {
        app.$modal.close({ type: 'LOADING' });
      });
    }
  }, [app.$loading]);

  return (
    <Switch>
      <Route path="/login" component={routes.Login} />
      <Route path="/change-password" component={routes.ChangePasswordPage} />
      <Route component={AuthedRouter} />
    </Switch>
  );
};

export default observer(RouterView);
