import { useMemo } from 'react';
import { format } from 'date-fns';
import { toThousandCommas } from '~/filters';
import { agencies } from '~/hooks/constants';
import { DataTable } from '~/components/commons/DataTable/DataTable';
import { Box, Link } from './DataTable.styled';

type ClinicsDataTableProps = {
  data: any;
  orderBy: { id: any; value: any };
  pagination: {
    page: number;
    limit: number;
  };
  onChangePagination: (params: any) => void;
};

export const ClinicsDataTable = (props: ClinicsDataTableProps) => {
  const { data, orderBy } = props;
  const items = useMemo(() => data?.data ?? [], [data]);

  const schema = useMemo(
    () => ({
      columns: [
        {
          id: 'id',
          name: 'ID',
          value: (item: any) => (
            <Link to={`/clinics/${item.id}`}>{item.id}</Link>
          ),
        },
        {
          id: 'status',
          name: '상태',
          value: (item: any) => {
            return !(item.status == null || item.status === '') ? (
              item.status === 'active' ? (
                <span>사용중</span>
              ) : (
                <span style={{ color: 'red' }}>해지</span>
              )
            ) : (
              <span style={{ color: 'red' }}>해지</span>
            );
          },
        },
        {
          id: 'createdDate',
          name: '등록일',
          value: (item: any) => (
            <Box className={`date`}>
              {format(new Date(item.createdAt), 'yyyy-MM-dd')}
            </Box>
          ),
        },
        {
          id: 'name',
          name: '거래처명',
          value: (item: any) => (
            <Link to={`/clinics/${item.id}`}>{item.name}</Link>
          ),
        },
        {
          id: 'oldName',
          name: '구 거래처명',
          value: (item: any) => <Box>{item.oldName ? item.oldName : '-'}</Box>,
        },
        {
          id: 'employerNo',
          name: '사업자번호',
          value: (item: any) => item?.employerNo || '-',
        },
        {
          id: 'test',
          name: '계정유형',
          value: (item: any) => {
            return item.test ? '테스트 계정' : '병원 계정';
          },
        },
        {
          id: 'agency',
          name: '계약 대행사',
          value: (item: any) => {
            return (
              agencies.find(
                (agent: any) => agent.value === item?.additional?.agency
              )?.key || '없음'
            );
          },
        },
        {
          id: 'crmManagementPlan',
          name: <span>CRM 운영비</span>,
          value: (item: any) => {
            return (
              <span>
                {item.billingPlan?.crmManagementPlanPrice !== undefined &&
                item.billingPlan?.crmManagementPlanPrice !== null
                  ? toThousandCommas(item.billingPlan.crmManagementPlanPrice)
                  : '-'}
              </span>
            );
          },
        },
        {
          id: 'userCount',
          name: (
            <span>
              사용자 계정
              <br />
              (사용/계약)
            </span>
          ),
          value: (item: any) => {
            const userCount = item.userCount !== null ? item.userCount : '-';
            const contractLimit =
              item.billingPlan?.crmManagementPlanLimit !== undefined &&
              item.billingPlan?.crmManagementPlanLimit !== null
                ? item.billingPlan.crmManagementPlanLimit
                : '-';
            const isOver = !isNaN(contractLimit - userCount)
              ? contractLimit - userCount < 0
              : false;
            return (
              <>
                <span className={`${isOver ? 'c-danger' : ''}`}>
                  {toThousandCommas(userCount)}
                </span>{' '}
                / <span>{toThousandCommas(contractLimit)}</span>
              </>
            );
          },
        },
        {
          id: 'penchartPlanPrice',
          name: <span>펜차트 관리비</span>,
          value: (item: any) => {
            return (
              <span>
                {item.billingPlan?.penchartPlanPrice !== undefined &&
                item.billingPlan?.penchartPlanPrice !== null &&
                !isNaN(item.billingPlan?.penchartPlanPrice)
                  ? toThousandCommas(item.billingPlan.penchartPlanPrice)
                  : '-'}
              </span>
            );
          },
        },
        {
          id: 's3Size',
          name: (
            <span>
              펜차트 사용 용량(GB)
              <br />
              (사용/계약)
            </span>
          ),
          value: (item: any) => {
            const s3Size = item.s3Size !== null ? item.s3Size : '-';
            const contractLimit =
              item.billingPlan?.penchartPlanLimit !== undefined &&
              item.billingPlan?.penchartPlanLimit !== null
                ? item.billingPlan.penchartPlanLimit
                : '-';
            const isOver = !isNaN(contractLimit - s3Size)
              ? contractLimit - s3Size < 0
              : false;
            return (
              <>
                <span className={`${isOver ? 'c-danger' : ''}`}>
                  {toThousandCommas(s3Size)}
                </span>{' '}
                / <span>{toThousandCommas(contractLimit)}</span>
              </>
            );
          },
        },
        {
          id: 'signCertPlanPrice',
          name: <span>전자서명 관리비</span>,
          value: (item: any) => {
            return (
              <span>
                {item.billingPlan?.signCertPlanPrice !== undefined &&
                item.billingPlan?.signCertPlanPrice !== null
                  ? toThousandCommas(item.billingPlan.signCertPlanPrice)
                  : '-'}
              </span>
            );
          },
        },
        {
          id: 'signCertPlanLimit',
          name: <span>전자서명 사용수</span>,
          value: (item: any) => {
            return (
              <span>
                {item.billingPlan?.signCertPlanLimit !== undefined &&
                item.billingPlan?.signCertPlanLimit !== null
                  ? toThousandCommas(item.billingPlan.signCertPlanLimit)
                  : '-'}
              </span>
            );
          },
        },

        {
          id: 'number080PlanPrice',
          name: <span>080번호 관리비</span>,
          value: (item: any) => {
            return (
              <>
                <span>
                  {item.billingPlan?.number080PlanPrice !== undefined &&
                  item.billingPlan?.number080PlanPrice !== null
                    ? toThousandCommas(item.billingPlan.number080PlanPrice)
                    : '-'}
                </span>
              </>
            );
          },
        },
        {
          id: 'isUberSync',
          name: '보험청구 연동',
          value: (item: any) => {
            return item.isUberSync === true ? '연동' : '미연동';
          },
        },
        {
          id: 'insurancePlanPrice',
          name: <span>보험청구 관리비</span>,
          value: (item: any) => {
            return (
              <span>
                {item.billingPlan?.insurancePlanPrice !== undefined &&
                item.billingPlan?.insurancePlanPrice !== null
                  ? toThousandCommas(item.billingPlan.insurancePlanPrice)
                  : '-'}
              </span>
            );
          },
        },
        {
          id: 'insurancePlanLimit',
          name: <span>보험청구 사용 수</span>,
          value: (item: any) => {
            return (
              <>
                <span>
                  {item.billingPlan?.insurancePlanLimit !== undefined &&
                  item.billingPlan?.insurancePlanLimit !== null
                    ? toThousandCommas(item.billingPlan.insurancePlanLimit)
                    : '-'}
                </span>
              </>
            );
          },
        },
        {
          id: 'isCameraSync',
          name: '카메라 연동 사용',
          value: (item: any) => {
            return item.isCameraSync === true ? '사용' : '미사용';
          },
        },
        {
          id: 'cameraSyncPlanPrice',
          name: <span>카메라 연동 관리비</span>,
          value: (item: any) => {
            return (
              <span>
                {item.billingPlan?.cameraSyncPlanPrice !== undefined &&
                item.billingPlan?.cameraSyncPlanPrice !== null
                  ? toThousandCommas(item.billingPlan.cameraSyncPlanPrice)
                  : '-'}
              </span>
            );
          },
        },
        {
          id: 'balance',
          name: <span>문자잔액 (원)</span>,
          value: (item: any) => {
            return (
              <>
                <span>
                  {item?.balance
                    ? toThousandCommas(
                        String(
                          (!isNaN(item.balance?.amount)
                            ? item.balance?.amount
                            : 0) +
                            (!isNaN(item.balance?.freeAmount)
                              ? item?.balance?.freeAmount
                              : 0)
                        )
                      )
                    : 0}
                </span>
              </>
            );
          },
        },
        {
          id: 'totalAmount',
          name: <span>월 관리비 총액 (원)</span>,
          value: (item: any) => {
            return (
              <>
                <span>{toThousandCommas(item.totalManagementPrice)}</span>
              </>
            );
          },
        },
        {
          id: 'memo',
          name: '메모',
          value: (item: any) => {
            let memo = [];
            try {
              memo =
                item?.memo !== undefined &&
                item?.memo !== '' &&
                item?.memo !== null &&
                item?.memo !== '{}' &&
                item?.memo !== '[]'
                  ? JSON.parse(item.memo || null) || []
                  : [];
            } catch (error) {
              console.error('error parsing: ', error);
              memo = [];
            }
            return (
              <Box className={`memo`}>
                <div>
                  {memo.map((memoItem: any, idx: number) => {
                    return (
                      <div key={idx}>
                        {memoItem.content} ({memoItem.timestamp})
                      </div>
                    );
                  })}
                </div>
              </Box>
            );
          },
        },
        {
          id: 'printLogDate',
          name: '네모닉 최근 출력 일시',
          value: (item: any) => {
            return (
              <Box className={`date`}>
                {item.printLogDate
                  ? format(new Date(item.printLogDate), 'yyyy-MM-dd HH:mm:ss')
                  : '-'}
              </Box>
            );
          },
        },
        {
          id: '-',
          name: '최근접속일시',
          value: (item: any) => {
            return (
              <Box className={`date`}>
                {item.lastLoginDate
                  ? format(new Date(item.lastLoginDate), 'yyyy-MM-dd HH:mm:ss')
                  : '-'}
              </Box>
            );
          },
        },
      ],
    }),
    []
  );

  return (
    <>
      <DataTable
        data={items}
        schema={schema}
        sorts={[orderBy]}
        placeholder={'조회 결과가 없습니다.'}
        resizable
      />
    </>
  );
};

/**
 * certificationUserCount : 전자서명 사용
 * registrationUserCount: 간편접수
 * userCount: 계정 수
 * s3
 * balnace: 문자잔액
 */
