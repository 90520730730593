import { styled, Box as MuiBox } from '@mui/material';

export const Box = styled(MuiBox)`
  &.default-wrapper {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 48px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
  &.graycity-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .image-box {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      img {
        width: 140px;
        height: 140px;
      }
    }
  }
`;

export const Description = styled(MuiBox)`
  font-size: 18px;
`;
