import { observer } from 'mobx-react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { BrowserRouter } from 'react-router-dom';
import { iconConfig as setIconConfig } from '~/assets/configure/icon';
import RouterView from './components/app/RouterView';
import { HolidayProvider } from '~/store/holiday';
import './firebase';
import Modal from '~/components/app/Modal';
import { ApiProvider } from '~/components/providers/ApiProvider';
import { AuthProvider } from '~/components/providers/AuthProvider';
import { AuthStorageProvider } from '~/components/providers/AuthStorageProvider';
import { SnackbarProvider } from '~/components/providers/SnackbarProvider';
import { ImperativeModalProvider } from './components/providers/ImperativeModalProvider';
import { HttpProvider } from '~/components/providers/HttpProvider';
import '~/assets/styles/index.css';
import { useApp } from '~/hooks/useApp';
import { ko as fnsLocale } from 'date-fns/locale';

import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider_v5,
} from '@mui/material';
import { ThemeProvider } from 'styled-components';
import { theme, color, muiTheme } from './theme';
import { ModalView } from '~/ModalView';
import { ErrorBoundary } from './components/providers/ErrorBoundary';

registerLocale('ko', {
  ...ko,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1,
  },
});

setDefaultLocale('ko');
setDefaultOptions({ locale: fnsLocale });
setIconConfig();

function App() {
  const app = useApp();
  return (
    <>
      <ThemeProvider theme={{ color, ...theme }}>
        <CssBaseline />
        <MuiThemeProvider_v5 theme={muiTheme}>
          <BrowserRouter basename="/">
            <AuthStorageProvider>
              <HttpProvider>
                <ApiProvider>
                  <AuthProvider>
                    <HolidayProvider>
                      <SnackbarProvider>
                        <ImperativeModalProvider>
                          <ModalView />
                          <ErrorBoundary>
                            <RouterView />
                          </ErrorBoundary>
                        </ImperativeModalProvider>
                        {(app.modals || []).length > 0
                          ? app.modals.map((modal: any, idx: number) => (
                              <Modal key={idx} modal={modal} />
                            ))
                          : null}
                      </SnackbarProvider>
                    </HolidayProvider>
                  </AuthProvider>
                </ApiProvider>
              </HttpProvider>
            </AuthStorageProvider>
          </BrowserRouter>
        </MuiThemeProvider_v5>
      </ThemeProvider>
    </>
  );
}

export default observer(App);
