import { createTheme } from '@mui/material';

export const color = {
  primary: {
    unoblue: '#2C62F6',
    deepblue: '#293142',
  },
  bluegrey: {
    700: '#4A4E70',
    600: '#A1B1CA',
    500: '#D7E3F1',
    300: '#E6EEF8',
    200: '#F3F8FF',
    100: '#F9FBFF',
  },
  grey: {
    700: '#2D2D2D',
    600: '#3A3A3A',
    500: '#BBBBBB',
    300: '#E6E6E6',
    200: '#F1F1F1',
  },
  line: '#DEE2EC',
  alert: '#EB5757',
  common: {
    line: '#DEE2EC',
    bg: '#EDEFF1',
    white: '#FFFFFF',
    red: '#EB5757',
  },
};

export const theme = {
  palette: {
    primary: {
      light: '#95a3f9',
      main: '#2c62f6',
      dark: '#003ce6',
      contrastText: '#ffffff',
      unoblue: '#2C62F6',
      deepblue: '#293142',
    },
    success: {
      light: '#c1eaca',
      main: '#68d083',
      dark: '#00ba4f',
      contrastText: '#ffffff',
    },
    error: {
      light: '##ec9c9d',
      main: '#eb5757',
      dark: '##e13f3d',
      contrastText: '#ffffff',
    },
    dark: {
      light: '#6c6c6c',
      main: '#3a3a3a',
      dark: '#1a1a1a',
      contrastText: '#ffffff',
    },
    deepblue: {
      light: '#4c5870',
      main: '#293142',
      dark: '#293142',
      contrastText: '#ffffff',
    },
    layout: {
      line: '#dee2ec',
      background: '#edeff1',
    },
    bluegrey: {
      700: '#4A4E70',
      600: '#A1B1CA',
      500: '#D7E3F1',
      300: '#E6EEF8',
      200: '#F3F8FF',
      100: '#F9FBFF',
    },
    grey: {
      700: '#2D2D2D',
      600: '#3A3A3A',
      500: '#BBBBBB',
      400: '#BBBBBB',
      300: '#E6E6E6',
      200: '#F1F1F1',
    },
  },
};

export const muiTheme = createTheme(theme);
