import { ComponentProps } from 'react';
import { ReactComponent as Unchecked } from '@ic/checkbox.svg';
import { ReactComponent as Checked } from '@ic/checkbox-checked.svg';
import { ReactComponent as Close } from '@ic/close.svg';
import { ReactComponent as ChevronLeft } from '@ic/calendar/chevron_left.svg';
import { ReactComponent as ChevronRight } from '@ic/calendar/chevron_right.svg';
import { ReactComponent as SortIcon } from '@ic/ic-sorting-arrow.svg';
import { ReactComponent as DragDefaultIcon } from '@ic/drag.svg';
import { ReactComponent as CheckMark } from '@ic/ico_check_mark.svg';
import { ReactComponent as SearchIcon } from '@ic/ic-search.svg';
import { ReactComponent as PlusIcon } from '@ic/ic-plus.svg';
import { ReactComponent as MinusIcon } from '@ic/ic-minus.svg';
import { ReactComponent as ChevronUp } from '@ic/chevron_up.svg';
import { ReactComponent as CautionWhite } from '@ic/ic-triangle-noti.svg';

type IconVariant =
  | 'checked'
  | 'unchecked'
  | 'close'
  | 'chevron_left'
  | 'chevron_right'
  | 'chevron_up'
  | 'sorting-arrow'
  | 'drag'
  | 'check_mark'
  | 'search'
  | 'plus'
  | 'minus'
  | 'cautionWhite';

type IconProps = {
  variant: IconVariant;
} & Partial<Pick<ComponentProps<'svg'>, 'width' | 'height' | 'color'>>;

const Icon = ({ variant, ...rest }: IconProps) => {
  switch (variant) {
    case 'checked':
      return <Checked {...rest} />;
    case 'unchecked':
      return <Unchecked {...rest} />;
    case 'close':
      return <Close {...rest} />;
    case 'chevron_left':
      return <ChevronLeft {...rest} />;
    case 'chevron_right':
      return <ChevronRight {...rest} />;
    case 'chevron_up':
      return <ChevronUp {...rest} />;
    case 'sorting-arrow':
      return <SortIcon {...rest} />;
    case 'drag':
      return <DragDefaultIcon {...rest} />;
    case 'check_mark':
      return <CheckMark {...rest} />;
    case 'search':
      return <SearchIcon {...rest} />;
    case 'plus':
      return <PlusIcon {...rest} />;
    case 'minus':
      return <MinusIcon {...rest} />;
    case 'cautionWhite':
      return <CautionWhite {...rest} />;
    default:
      return <></>;
  }
};

export default Icon;
